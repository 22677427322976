import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    useTheme,
    styled,
    IconButton,
} from "@mui/material";
import { useState } from "react";
import { type SelectChangeEvent } from "@mui/material";
import { FaPlus } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
// TODO: add typing for filter fields
const filterFields = [
    {
        type: "string",
        name: "location",
        label: "Location",
    },
    {
        type: "string",
        name: "major",
        label: "Major",
    },
    {
        type: "string",
        name: "seniority",
        label: "Seniority",
    },
];

export enum NumberFilterOperator {
    EQUALS = "equals",
    NOT_EQUALS = "not equals",
    GREATER_THAN = "greater than",
    LESS_THAN = "less than",
    GREATER_THAN_OR_EQUAL_TO = "greater than or equal to",
    LESS_THAN_OR_EQUAL_TO = "less than or equal to",
}

export enum StringFilterOperator {
    EQUALS = "equals",
    NOT_EQUALS = "not equals",
    CONTAINS = "contains",
    DOES_NOT_CONTAIN = "does not contain",
    STARTS_WITH = "starts with",
    ENDS_WITH = "ends with",
}

export enum DateFilterOperator {
    EQUALS = "equals",
    NOT_EQUALS = "not equals",
    BEFORE = "before",
    AFTER = "after",
}

export interface FilterField {
    type: "number" | "string" | "date" | "boolean" | "enum";
    name: string;
    label: string;
    value?: any;
}

export interface NumberFilterField extends FilterField {
    type: "number";
    operator: NumberFilterOperator;
    value: number;
}

export interface StringFilterField extends FilterField {
    type: "string";
    operator: StringFilterOperator;
    value: string;
}

export interface DateFilterField extends FilterField {
    type: "date";
    operator: DateFilterOperator;
    value: Date;
}

export interface BooleanFilterField extends FilterField {
    type: "boolean";
    value: boolean;
}

export interface EnumFilterField extends FilterField {
    type: "enum";
    value: string;
    options: string[];
}

export type FilterFieldGeneric =
    | NumberFilterField
    | StringFilterField
    | DateFilterField
    | BooleanFilterField
    | EnumFilterField;

export interface FilterBuilderProps {
    onChange: (value: FilterFieldGeneric[]) => void;
    value: FilterFieldGeneric[];
}

export default function FilterBuilder(props: FilterBuilderProps) {
    const { value: filters, onChange: setFilters } = props;
    const [isFilterAdderShown, setIsFilterAdderShown] = useState(false);
    const [showFirstFilterAdder, setShowFirstFilterAdder] = useState(true);

    const theme = useTheme();
    const [isFirstRow, setIsFirstRow] = useState(true);
    const [selectError, setSelectError] = useState(false); // State variable for select error
    const [inputError, setInputError] = useState(false); // State variable for input error

    const handleAddFilter = (newField: FilterFieldGeneric) => {
        setShowFirstFilterAdder(false);
        setIsFilterAdderShown(false);
        setFilters([...filters, newField]);
        setIsFirstRow(false);
        setSelectError(false); // Reset select error
        setInputError(false); // Reset input error
    };

    const onFieldChange = (
        updatedField: FilterFieldGeneric,
        indexToUpdate: number
    ) => {
        const newFilters = filters.map((filter, index) =>
            index === indexToUpdate ? updatedField : filter
        );
        setFilters(newFilters);
    };

    const onStringFieldChange = (field: StringFilterField) => {
        const indexToUpdate = filters.findIndex(f => f.name === field.name);
        onFieldChange(field, indexToUpdate);
    };

    const onNumberFieldChange = (field: NumberFilterField) => {
        const indexToUpdate = filters.findIndex(f => f.name === field.name);
        onFieldChange(field, indexToUpdate);
    };

    const onEnumFieldChange = (field: EnumFilterField) => {
        const indexToUpdate = filters.findIndex(f => f.name === field.name);
        onFieldChange(field, indexToUpdate);
    };

    const onDelete = (indexToDelete: number) => {
        setFilters(filters.filter((_, index) => index !== indexToDelete));
        setIsFilterAdderShown(false);
        if (filters.length === 1) {
            setShowFirstFilterAdder(true);
            setIsFirstRow(true);
        }
    };

    function FilterAdder() {
        return (
            <Grid
                container
                alignItems="center"
                spacing={theme.spacing(1)}
                mb={theme.spacing(1)}
            >
                {/* <Grid item xs={1}>
                    <DisabledTextField
                        value={isFirstRow ? "Where" : "And"}
                    ></DisabledTextField>
                </Grid> */}
                <Grid item xs={4}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="select-label">Select a field</InputLabel>
                        <Select
                            labelId="select-label"
                            id="simple-select"
                            value={""}
                            size="small"
                            label="Select a field"
                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                        >
                            {filterFields.map((field, index) => (
                                <MenuItem
                                    key={`filter-field-${index}`}
                                    value={field.name}
                                    onClick={() => {
                                        // TODO: this is related to the upper task
                                        handleAddFilter(field as FilterFieldGeneric);
                                    }}
                                >
                                    {field.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    const showFilterAdder = () => {
        if (!isFilterAdderShown) {
            setIsFilterAdderShown(true);
        }
    };

    function RenderFilterConditions(field: FilterFieldGeneric, index: number) {
        switch (field.type) {
            case "string":
                return (
                    <StringFilter
                        field={field}
                        onFieldChange={onStringFieldChange}
                        onDelete={() => {
                            onDelete(index);
                        }}
                        index={index}
                    />
                );
            case "number":
                return (
                    <NumberFilter
                        field={field}
                        onFieldChange={onNumberFieldChange}
                        onDelete={() => {
                            onDelete(index);
                        }}
                        index={index}
                    />
                );
            case "enum":
                return (
                    <EnumFilter
                        field={field}
                        onFieldChange={onEnumFieldChange}
                        onDelete={() => {
                            onDelete(index);
                        }}
                        index={index}
                    />
                );
            default:
                return null;
        }
    }

    return (
        <Grid container flexDirection="column">
            <Grid item>
                {filters.length === 0 && showFirstFilterAdder && FilterAdder()}
                {filters.map((filter, index) => (
                    <Stack mb={theme.spacing(1)} key={index}>
                        {RenderFilterConditions(filter, index)}
                    </Stack>
                ))}
                {isFilterAdderShown && <FilterAdder />}
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={showFilterAdder}
                    color="primary"
                    startIcon={<FaPlus />}
                >
                    Add another filter
                </Button>
            </Grid>
        </Grid>
    );
}

interface StringFilterProps {
    field: StringFilterField;
    onFieldChange: (field: StringFilterField) => void;
    onDelete: (index: number) => void;
    index: number;
}

function StringFilter(props: StringFilterProps) {
    const theme = useTheme();
    const isFirstRow = props.index === 0;

    const [value, setValue] = useState(props.field.value || "");
    const [isValueError, setIsValueError] = useState(value.trim() === "");
    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedValue = e.target.value;
        setValue(updatedValue);
        setIsValueError(updatedValue.trim() === ""); // Update the error state based on the new value
        props.onFieldChange({
            ...props.field,
            value: updatedValue,
        });
    };

    const isError = value.trim() === "";
    return (
        <Grid container spacing={theme.spacing(1)} alignItems="center">
            <Grid item xs={3}>
                <DisabledTextField value={props.field.label} />
            </Grid>
            <Grid item xs={3}>
                <Select
                    fullWidth
                    value={props.field.operator || ""}
                    onChange={e => {
                        props.onFieldChange({
                            ...props.field,
                            operator: e.target.value as StringFilterOperator,
                        });
                    }}
                    size="small"
                >
                    <MenuItem value={StringFilterOperator.EQUALS}>Equals</MenuItem>
                    <MenuItem value={StringFilterOperator.NOT_EQUALS}>
                        Not Equals
                    </MenuItem>
                    <MenuItem value={StringFilterOperator.CONTAINS}>Contains</MenuItem>
                    <MenuItem value={StringFilterOperator.DOES_NOT_CONTAIN}>
                        Does Not Contain
                    </MenuItem>
                    <MenuItem value={StringFilterOperator.STARTS_WITH}>
                        Starts With
                    </MenuItem>
                    <MenuItem value={StringFilterOperator.ENDS_WITH}>Ends With</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={4}>
                <CustomTextField
                    error={isError}
                    type="string"
                    value={value}
                    onChange={handleTextFieldChange}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    color="secondary"
                    size="medium"
                    onClick={() => {
                        props.onDelete(props.index);
                    }}
                >
                    <MdDeleteForever />
                </IconButton>
            </Grid>
        </Grid>
    );
}

interface NumberFilterProps {
    field: NumberFilterField;
    onFieldChange: (field: NumberFilterField) => void;
    onDelete: (index: number) => void;
    index: number;
}

function NumberFilter(props: NumberFilterProps) {
    const theme = useTheme();
    const isFirstRow = props.index === 0;
    const [value, setValue] = useState(props.field.value || "");
    const [isValueError, setIsValueError] = useState(value === "");
    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedValue = e.target.value;
        setValue(updatedValue);
        setIsValueError(updatedValue.trim() === "");
        const numericValue = parseFloat(updatedValue);
        props.onFieldChange({
            ...props.field,
            value: numericValue,
        });
    };
    const isError = value === "";

    return (
        <Grid container spacing={theme.spacing(1)} alignItems="center">
            <Grid item xs={3}>
                <DisabledTextField value={props.field.label} />
            </Grid>
            <Grid item xs={3}>
                <Select
                    fullWidth
                    value={props.field.operator || ""}
                    onChange={e => {
                        props.onFieldChange({
                            ...props.field,
                            operator: e.target.value as NumberFilterOperator,
                        });
                    }}
                    size="small"
                >
                    <MenuItem value={NumberFilterOperator.EQUALS}>Equals</MenuItem>
                    <MenuItem value={NumberFilterOperator.NOT_EQUALS}>
                        Not Equals
                    </MenuItem>
                    <MenuItem value={NumberFilterOperator.GREATER_THAN}>
                        Greater Than
                    </MenuItem>
                    <MenuItem value={NumberFilterOperator.LESS_THAN}>Less Than</MenuItem>
                    <MenuItem value={NumberFilterOperator.GREATER_THAN_OR_EQUAL_TO}>
                        Greater Than Or Equal To
                    </MenuItem>
                    <MenuItem value={NumberFilterOperator.LESS_THAN_OR_EQUAL_TO}>
                        Less Than Or Equal To
                    </MenuItem>
                </Select>
            </Grid>
            <Grid item xs={4}>
                <CustomTextField
                    error={isError}
                    type="number"
                    value={value}
                    onChange={handleTextFieldChange}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        props.onDelete(props.index);
                    }}
                >
                    <MdDeleteForever />
                </IconButton>
            </Grid>
        </Grid>
    );
}

interface EnumFilterProps {
    field: EnumFilterField;
    onFieldChange: (field: EnumFilterField) => void;
    onDelete: (index: number) => void;
    index: number;
}

function EnumFilter(props: EnumFilterProps) {
    const theme = useTheme();
    const isFirstRow = props.index === 0;
    return (
        <Grid container spacing={theme.spacing(1)} alignItems="center">
            <Grid item xs={4}>
                <DisabledTextField value={props.field.label} />
            </Grid>
            <Grid item xs={6}>
                <Select
                    fullWidth
                    value={props.field.value || ""}
                    onChange={e => {
                        props.onFieldChange({
                            ...props.field,
                            value: e.target.value,
                        });
                    }}
                    size="small"
                >
                    {props.field.options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    size="medium"
                    color="secondary"
                    onClick={() => {
                        props.onDelete(props.index);
                    }}
                >
                    <MdDeleteForever />
                </IconButton>
            </Grid>
        </Grid>
    );
}
const DisabledTextField = styled(TextField)(
    ({ theme }) => ({
        pointerEvents: "none",
        width: "100%",

        "& .MuiOutlinedInput-input": {
            padding: "8.5px 14px", // Adjust padding as needed
            opacity: "0.4",
        },
    }),
    {
        defaultProps: {
            disabled: true,
            fullWidth: true,
            size: "small",
            variant: "outlined",
        },
    }
);

const CustomTextField = styled(TextField)(
    ({ theme }) => ({
        width: "100%",

        "& .MuiOutlinedInput-input": {
            padding: "8.5px 14px", // Adjust padding as needed
            opacity: "0.4",
        },
    }),
    {
        defaultProps: {
            fullWidth: true,
            size: "small",
            variant: "outlined",
        },
    }
);
