import { Box } from "@mui/material";

interface IframeAnimationProps {
  src: string;
  sx?: any;
}

export default function IframeAnimation({
  src,
  sx = {},
  ...props
}: IframeAnimationProps) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
        ...sx,
      }}
    >
      <iframe
        width="100%"
        height="100%"
        src={src}
        style={{
          border: "none",
          overflow: "hidden",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        {...props}
      />
    </Box>
  );
}
