// src/Login.tsx
import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Grid, Stack, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import dataQueueIcon from '../images/dqIconBlack.png';
import axiosInstance from '../instance/AxiosInstance';
import IframeAnimation from '../component/IframeAnimation';
import { ReactTyped } from 'react-typed';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const SENTENCES = [
    "Build your own data science tools",
    "Build your own retrieval system",
    "Build your next idea",
    "Build your next product",
    "Build your next business",
    "Build your next innovation",
    "Build your next AI-powered solution",
    "Build your next Medical AI solution",
    "Build your next intelligent system",
  ];
  const handleLogin = async () => {
    try {
      const response = await axiosInstance.post('/auth/login', {
        username,
        password,
      });
      localStorage.setItem('token', response.data.token);
      navigate('/search');
    } catch (error) {
      console.error('Login failed', error);
      alert('Login failed');
    }
  };

  return (
    <Box sx={{
      height: "100vh", width: "100vw"
    }} >
      <Grid container spacing={0} sx={{ height: "100%" }}>
        {/* Hero */}
        <Grid item xs={8} sx={{ p: 5, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Typography variant="h3" color="#ffff">
              <ReactTyped
                strings={SENTENCES}
                typeSpeed={40}
                backSpeed={50}
                loop
              />
            </Typography>
          </Box>
          <IframeAnimation src="https://my.spline.design/cubic-398375a21446192e777f85ce78c926e2/" />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ alignSelf: 'flex-start' }}>
            <img src={dataQueueIcon} alt="dataQueue" style={{ borderRadius: "40px", maxHeight: "75px" }} />
          </Box>
        </Grid>

        {/* Login Prompts */}
        <Grid
          item
          xs={4}
          sx={{
            backgroundColor: "#ffff",
            p: 5,
            height: '100%',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Box width="auto" >
              <img width="100%" src={logo} alt="foras.jpg" style={{ borderRadius: "40px", maxHeight: "230px" }} />
            </Box>
            <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: 14, sm: 17, md: 25 } }}>
              Foras CV Search
            </Typography>
            <TextField
              label="Username"
              variant="outlined"
              margin="normal"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              margin="normal"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleLogin} sx={{ color: "white" }}>
              Login
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;


