// src/Search.tsx
import { Backdrop, Box, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileUploadButton from '../component/FileUploadButton';
import Pagination from '../component/Pagination';
import SnackbarComponent from '../component/SnackBarComponent';
import axiosInstance from '../instance/AxiosInstance';
import Cards from '../component/Cards';
import logo from '../images/BrainHire2.png';
import type { DocumentProps } from '../types/types';

import FilterBuilder, { FilterFieldGeneric } from '../util/FilterBuilder';
import { ReactTyped } from 'react-typed';
import ErrorSnackbarComponent from '../component/ErrorSnackBar';


const Search: React.FC = () => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [searchResults, setSearchResults] = useState<DocumentProps[]>([]);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState<FilterFieldGeneric[]>([]);
    const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);

    const token = localStorage.getItem('token') || '';
    const [totalResults, setTotalResults] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false); // Loader state
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [severity, setSeverity] = useState<"error" | "info" | "success" | "warning">('success');
    async function fetchAllDocuments(page: number, size: number) {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`/documents?page=${page - 1}&size=${size}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const documents: DocumentProps[] = response.data.documents;

            setSearchResults(documents);
            setSearchValue("");
            const totalDocuments = response.data.total;
            setTotalResults(totalDocuments);
            setTotalPages(Math.ceil(totalDocuments / size));

        } catch (error) {
            console.error('Error fetching documents:', error);
        }
        finally {
            setLoading(false);
        }
    };
    const sendQuery = async (query: string, page: number, size: number) => {
        try {
            const response = await axiosInstance.get(`/query?query=${query}&page=${page - 1}&size=${size}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });


            const documents: DocumentProps[] = response.data.documents;
            setSearchResults(documents);
            setTotalResults(response.data.total);
            setTotalPages(Math.ceil(response.data.total / size));


        } catch (error) {
            console.error('Error sending query:', error);
        }
        finally {
            setLoading(false); // Hide loader
        }
    };

    function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value);
        setCurrentPage(1);
    };
    const handleFileUpload = async (files: FileList | null) => {
        if (files) {
            try {
                setLoading(true);
                const formData = new FormData();
                for (let i = 0; i < files.length; i++) {
                    formData.append('cv', files[i]);
                }

                const response = await axiosInstance.post('/documents', formData, {

                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });

                let uploadedFiles = 0;
                let duplicatedFiles = 0;
                let failedFiles = 0;
                let failedFileNames: string[] = [];

                response.data.results.map((file: any) => {
                    if (file.message === "File already exists") {
                        duplicatedFiles++;
                    } else if (file.message === "File indexed successfully") {
                        uploadedFiles++;
                    } else {
                        failedFiles++;
                        failedFileNames.push(file.id);
                    }
                });

                let successMessage = '';
                let errorMessage = '';

                if (uploadedFiles > 0) {
                    successMessage += `${uploadedFiles} File(s) uploaded successfully\n`;
                }
                if (duplicatedFiles > 0) {
                    successMessage += `${duplicatedFiles} File(s) already exist\n`;
                }
                if (failedFiles > 0) {
                    errorMessage += `${failedFiles} File(s) failed to upload\n`;
                    failedFileNames.forEach(name => {
                        errorMessage += `- ${name}\n`;
                    });
                }
                setSeverity("success");
                setSnackMessage(successMessage.trim()); // Trim to remove trailing newline
                setErrorMessage(errorMessage.trim()); // Trim to remove trailing newline

                setIsSnackbarOpen(uploadedFiles > 0 || duplicatedFiles > 0);
                setIsErrorSnackbarOpen(failedFiles > 0);

            } catch (error) {
                setSnackMessage('Error uploading documents ' + error,);

                setSeverity("error");
                setIsSnackbarOpen(true);
            } finally {

                setLoading(false);
            }
        }

    };
async function uploadFetch(files: FileList | null){
    try {
        // First, await handleFileUpload
        await handleFileUpload(files);
        // Then, await fetchAllDocuments
        const fetchDocumentsWithTimeout = new Promise<void>((resolve, reject) => {
            setTimeout(async () => {
                try {
                    setLoading(true);
                    await fetchAllDocuments(currentPage, pageSize);
                    resolve();
                } catch (error) {
                    reject(error);
                }finally {
                    setLoading(false);
                }
            }, 1000); // Timeout set to 5 seconds (5000 milliseconds)
        });

        await fetchDocumentsWithTimeout;

    } catch (error) {
        console.error('Error in uploadFetch:', error);
        // Handle errors as needed
    }


}
    const handleDeleteDocument = async (documentId: string) => {
        try {

            setLoading(true);
            await axiosInstance.delete(`/documents/${documentId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const currentSearchValue = searchValue;
            const currentPageNumber = currentPage;

            await fetchAllDocuments(currentPage, pageSize);
            setSnackMessage('Document Deleted');
            setSeverity("error");
            setIsSnackbarOpen(true);
            if (currentSearchValue) {
                sendQuery(currentSearchValue, currentPageNumber, pageSize);
                setSearchValue(currentSearchValue);
            }

        } catch (error) {
            setSnackMessage('Failed to delete');
            setSeverity("error");
            setIsSnackbarOpen(true);
            console.error('Error deleting document:', error);
        } finally {
            setLoading(false);
        };

    };
    const handleViewDocument = async (document: DocumentProps) => {

        try {
            const response = await axiosInstance.get(`/documents/${document.id}/file`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                responseType: 'blob' // This ensures the response is a Blob object (binary data)
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const extension = document.name.split('.').pop()?.toLowerCase();
            if (extension === 'pdf') {
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            } else {
                handleDownloadDocument(document);
            }


        } catch (error) {
            console.error('Error viewing document:', error);
        }

    }
    const handleDownloadDocument = async (doc: DocumentProps) => {

        try {
            const response = await axiosInstance.get(`/documents/${doc.id}/file`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },

                responseType: 'blob'

            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${doc.name}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error('Error viewing document:', error);
        }

    };
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
        if (searchValue) {
            sendQuery(searchValue, page, pageSize);
        } else {
            fetchAllDocuments(page, pageSize);
        }
    };

    useEffect(() => {
        if (searchValue) {
            const handler = setTimeout(() => {
                const value = searchValue.toLowerCase();
                sendQuery(value, currentPage, pageSize);
            }, 500); // 500ms debounce time

            return () => {
                clearTimeout(handler);
            };
        } else {
            fetchAllDocuments(currentPage, pageSize);
        }
    }, [searchValue, currentPage, pageSize]);
    return (
        <>
            <Stack p={{ xs: 2, sm: 4, md: 5, lg: 5 }} spacing={2}>
                <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start">
                    <img src={logo} alt="logo" style={{ maxWidth: '100%', height: 'auto', maxHeight: "75px" }} />

                    {/* <Link href="" >
                        العربية
                    </Link> */}

                </Box>
                <Stack direction="row" spacing={2} alignItems="center">
                    <TextField fullWidth value={searchValue} placeholder="Search" onChange={handleSearchChange} />
                    <Typography variant="subtitle1">{totalResults} document(s) found</Typography>
                    <FileUploadButton onUpload={uploadFetch} />
                </Stack>

                <Stack  direction="column" my={1}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", color: "black", }}>
                    <Typography variant="subtitle1" sx={{ fontStyle: 'italic',opacity: 0.6, fontSize: 16,fontWeight:400}}>
                        [Filters coming soon]   
                    </Typography>
                </Box>
                    <FilterBuilder value={filters} onChange={setFilters} />
                </Stack>

                <Pagination count={totalPages} page={currentPage} onPageChange={handlePageChange} />
                <Box display="flex"
                    justifyContent="center">
                    <Grid container spacing={2} alignItems='center' >
                        {searchResults.map(document => (
                            <Grid item key={document.id} xs={12} md={6} lg={4} >
                                <Cards onDelete={handleDeleteDocument} document={document} onPress={handleViewDocument} onDownload={handleDownloadDocument} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Stack direction="column" spacing={9} sx={{ position: "relative" }}>
                    <SnackbarComponent
                        isOpen={isSnackbarOpen}
                        onClose={() => setIsSnackbarOpen(false)}
                        message={snackMessage}
                        severity={severity}
                    />
                    <ErrorSnackbarComponent
                        isOpen={isErrorSnackbarOpen}
                        onClose={() => { setIsErrorSnackbarOpen(false) }}
                        message={errorMessage}
                    />
                </Stack>
            </Stack>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="primary" />
            </Backdrop>
        </>
    );
};

export default Search;
