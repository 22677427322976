import React from 'react';
import { Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FaFileUpload } from 'react-icons/fa';

interface FileUploadButtonProps {
    onUpload: (files: FileList | null) => void;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onUpload }) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            onUpload(event.target.files);
        }
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <label>
            {isSmallScreen ? (
                <IconButton color="primary" component="span">
                    <FaFileUpload />
                </IconButton>
            ) : (
                <Button variant="outlined" component="span">
                    Upload Files
                </Button>
            )}
            <input type="file" style={{ display: 'none' }} onChange={handleFileChange} multiple accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .txt"/>
        </label>
    );
};

export default FileUploadButton;
