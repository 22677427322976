// src/Pagination.tsx
import React from 'react';
import { Pagination as MUIPagination, Stack } from '@mui/material';

interface PaginationProps {
  count: number;
  page: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ count, page, onPageChange }) => {
  return (
    <Stack spacing={2} alignItems="center">
      <MUIPagination count={count} page={page} onChange={onPageChange} />
    </Stack>
  );
};

export default Pagination;
