import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import logger from '../util/Logger';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

interface PDFViewerComponentProps {
    pdfUrl: string;
}

const PDFViewerComponent: React.FC<PDFViewerComponentProps> = ({ pdfUrl }) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const loadingTask = pdfjs.getDocument(pdfUrl);
        loadingTask.promise
          .then(() => setLoading(false))
          .catch((error) => {
            logger.error('Error loading PDF:', error); 
            setLoading(false);
          });
    
        return () => {
          // Cleanup function to properly terminate the worker when component unmounts
          loadingTask.destroy();
        };
      }, [pdfUrl]);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    function onError(error: any) {
        logger.error('PDF Viewer Error:', error); 
    }
    function onSourceError(error: any) {
        logger.error('PDF Source Error:', error);
    }
    function onLoadError(error: any) {
        logger.error('PDF Load Error:', error);
    }
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        // setNumPages(numPages);
        logger.info('Document loaded successfully');
    }
    return (
        <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative', }} ref={containerRef}>
            {!loading && (
                <Document
                    file={pdfUrl}
                    onError={onError}
                    onSourceError={onSourceError}
                    onLoadError={onLoadError}
                    loading={<div>Loading PDF...</div>}
                    noData={<div>No PDF file specified.</div>}
                    error={<div>Error while loading PDF.</div>}
                    onLoadSuccess={onDocumentLoadSuccess}>
                    <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>

                        <Page
                            pageNumber={1}
                            width={containerWidth}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                        />
                    </Box>
                </Document>
            )}
    </Box>
    );
};

export default PDFViewerComponent;
