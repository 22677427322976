import React from 'react';
import { Snackbar, Alert, Slide, SlideProps } from '@mui/material';

interface ErrorSnackbarProps {
    isOpen: boolean;
    onClose: () => void;
    message: string;
}

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

const ErrorSnackbarComponent: React.FC<ErrorSnackbarProps> = ({ isOpen, onClose, message }) => {
    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={onClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={SlideTransition}
            sx={{
                minWidth: '300px', // Increase minWidth
                maxWidth: '400px', // Increase maxWidth if necessary
                '& .MuiAlert-root': {
                    fontSize: '1rem', // Increase font size 
                }
            }}
        >
            <Alert severity="error" onClose={onClose} sx={{ whiteSpace: 'pre-line' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default ErrorSnackbarComponent;
