import React from 'react';
import { Snackbar, Alert, Slide, SlideProps } from '@mui/material';

interface SnackbarComponentProps {
    isOpen: boolean;
    onClose: () => void;
    message: string;
    severity: 'error' | 'success' | 'info' | 'warning';
}
function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({ isOpen, onClose, message, severity }) => {
    return (
        <Snackbar open={isOpen}
            autoHideDuration={5000}
            onClose={onClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            TransitionComponent={SlideTransition}
            sx={{
                minWidth: '300px', // Increase minWidth
                maxWidth: '400px', // Increase maxWidth if necessary
                '& .MuiAlert-root': {
                    fontSize: '1rem', // Increase font size 
                }

            }}>

            <Alert severity={severity} onClose={onClose} sx={{ whiteSpace: 'pre-line' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarComponent;
