import { Box } from '@mui/material';
import React from 'react';

interface DocumentViewerProps {
  fileUrl: string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ fileUrl }) => {
  const newString = fileUrl.substring(5);

  return (
    <Box style={{ width: '100%', height: '200px' }}>
      <iframe className={"docx"} width="100%" height="100%" src={`https://docs.google.com/gview?url=${newString}&embedded=true`}>
      </iframe>
    </Box>
  );
};

export default DocumentViewer;
