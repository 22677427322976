import React from 'react';
import { Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, IconButton, Paper, Popover, Stack, Tooltip, Typography } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaDownload, FaEye, FaFileWord, FaTrashAlt } from "react-icons/fa";
import { FaFile } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import { useEffect, useState } from "react";
import axiosInstance from "../instance/AxiosInstance";
import type { DocumentProps } from "../types/types";
import PDFViewerComponent from './PDFViewerComponent';
import DocViewer from './DocViewer';

interface cardProps {
    onDownload: (document: DocumentProps) => void;
    onPress: (document: DocumentProps) => void;
    onDelete: (id: string) => void;
    document: DocumentProps;
}
export default function Cards(props: cardProps) {
    const [contentUrl, setContentUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const extension = props.document?.name.split('.').pop()?.toLowerCase();
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    useEffect(() => {
        const fetchDocumentContent = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/documents/${props.document.id}/file`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    responseType: 'blob'
                });

                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                setContentUrl(url);
            } catch (err) {
                setError('Failed to load document content');
            } finally {
                setLoading(false);
            }
        };

        fetchDocumentContent();
    }, [props.document.id]);

    const renderContentPreview = () => {
        if (loading) return <CircularProgress />;
        if (error) return <Typography color="error">{error}</Typography>;
        if (!contentUrl) return <Typography variant="body2" color="textSecondary">No preview available</Typography>;

        switch (extension) {
            case 'pdf':
                return (
                    <Tooltip title="Click to view PDF">
                        <Box onClick={() => props.onPress(props.document)} sx={{
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: '#f0f0f0', // Background color on hover
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', // Box shadow on hover
                            }, cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center', height: "200px"
                        }}>
                            <PDFViewerComponent pdfUrl={contentUrl} />
                        </Box>
                    </Tooltip>
                );
            case 'jpg':
            case 'jpeg':
            case 'png':
                return (
                    <Tooltip title="Click to view Image">
                        <Box onClick={() => props.onPress(props.document)} sx={{
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: '#f0f0f0', // Background color on hover
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', // Box shadow on hover
                            }, cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center', height: "200px"
                        }}>
                            <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative', }}>
                                <img src={contentUrl} alt="File Preview" style={{ maxWidth: '100%' }} />
                            </Box>
                        </Box>
                    </Tooltip>
                )
            case 'doc':
            case 'docx':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
                        <DocViewer fileUrl={contentUrl} />
                    </Box>
                )
            default:
                return <Typography variant="body2" color="textSecondary">No preview available</Typography>;
        }
    };
    return (
        <Card sx={{ p: 2 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: extension === "pdf" ? "#cc4444" : extension === "doc" || extension === "docx" ? "#007effde" : "lightgray" }} aria-label="recipe">
                        {extension === "pdf" ? <FaFilePdf /> : extension === "doc" || extension === "docx" ? <FaFileWord /> : <FaFile />}
                    </Avatar>
                }
                action={
                    <>
                        <IconButton aria-label="actions" onClick={handlePopoverOpen}>
                            <BsThreeDotsVertical />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >

                            <Stack sx={{ p: 1 }} spacing={1} alignItems='flex-start'>
                                <Button color="secondary" startIcon={<FaDownload />} onClick={() => {
                                    props.onDownload(props.document)
                                    handlePopoverClose();
                                }
                                }>
                                    <Typography sx={{ ml: 1 }}>Download</Typography>
                                </Button>
                                {extension === 'pdf' && (
                                    <Button startIcon={<FaEye />} onClick={() => { props.onPress(props.document); handlePopoverClose(); }}>
                                        <Typography sx={{ ml: 1 }}>View</Typography>
                                    </Button>
                                )}
                                <Button color="error" startIcon={<FaTrashAlt />} onClick={() => { props.onDelete(props.document.id); handlePopoverClose(); }}>
                                    <Typography sx={{ ml: 1 }}>Delete</Typography>
                                </Button>
                            </Stack>
                        </Popover>
                    </>
                }
                title={truncateText(props.document.name, 30)}
            />
            <CardContent sx={{ p: 0 }}>
                {loading ? (
                    <Box display="flex" alignItems='center' width="100%">

                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    renderContentPreview()
                )}
            </CardContent>
        </Card>
    );
}

const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength) + '...';
    }
}

{/* <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={1} minHeight={100}>
            <Stack direction="row" alignItems="center" minHeight={100} flexGrow={1} onClick={() => props.onPress(props.document)} height="inherit">
                <Typography variant="h6" >{truncateText(props.document.name, 30)}</Typography>
            </Stack>
            <IconButton size="small" color='error'
                onClick={(e) => {
                    if (window.confirm('Are you sure you want to delete this document?' + props.document.id)) {
                        props.onDelete(props.document.id);
                    }
                }}
            >
                <FaRegTrashAlt />
            </IconButton>
        </Stack> */}